import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './controls.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import IconButton from '@mui/material/IconButton/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import data_types from '../../data/data_types';

class Nx_Ctrl_TextInput extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.inputStyle = this.props.inputStyle ? this.props.inputStyle : { fontSize: this.props.fontSize };
      this.inputLabelStyle = this.props.inputLabelStyle
         ? this.props.inputLabelStyle
         : { fontSize: this.props.fontLabelSize };
   }

   onChange = (event) => {
      let value = event.target.value;
      if (!value) {
         this.props.onChange(null);
         return;
      }

      if (this.props.type === data_types.number) {
         this.handleNumberOnChange(value);
         return;
      }

      this.props.onChange(value);
   };

   handleNumberOnChange = (value) => {
      if (this.props.type === data_types.number && this.props.fieldConfig.hasOwnProperty('max')) {
         if (parseInt(value) <= this.props.fieldConfig.max) {
            this.props.onChange(value);
            return;
         } else {
            this.props.onChange(this.props.fieldConfig.max);
            return;
         }
      }
      if (this.props.type === data_types.number && this.props.fieldConfig.hasOwnProperty('min')) {
         if (parseInt(value) >= this.props.fieldConfig.min) {
            this.props.onChange(value);
            return;
         } else {
            this.props.onChange(this.props.fieldConfig.min);
            return;
         }
      }
      this.props.onChange(value);
   };

   render() {
      return (
         <Fragment>
            <TextField
               label={this.props.fieldConfig ? this.props.fieldConfig.label : this.props.label}
               value={this.props.value !== null && this.props.value !== undefined ? this.props.value : ''}
               onWheel={(e) => e.target.blur()}
               variant={this.props.variant}
               disabled={this.props.disabled || this.props.fieldConfig?.disabled}
               error={this.props.error}
               autoFocus={this.props.autoFocus}
               fullWidth={this.props.fullWidth}
               helperText={this.props.helperText && this.props.helperText.length ? this.props.helperText : ''}
               type={this.props.type}
               inputProps={{
                  autoComplete: 'new-password',
                  readOnly: this.props.read_only,
                  // style: this.inputStyle,
                  min: this.props.fieldConfig ? this.props.fieldConfig.min : this.props.min,
                  max: this.props.fieldConfig ? this.props.fieldConfig.max : this.props.max
               }}
               InputLabelProps={{ style: this.inputLabelStyle }}
               onKeyDown={this.props.onKeyDown}
               onChange={this.onChange}
               multiline={this.props.fieldConfig ? this.props.fieldConfig.multiline : this.props.multiline}
               rows={this.props.fieldConfig ? (this.props.fieldConfig.multiline ? 5 : null) : null}
               size={this.props.size}
               className={`nx_textfield${this.props.read_only ? '_read_only' : ''}  ${
                  this.props.className ? this.props.className : ''
               } ${this.props.error ? 'nx_error' : ''} ${
                  this.props.type === data_types.number && this.props.fieldConfig.phone ? 'nx_phone' : ''
               }`}
            ></TextField>
            {this.props.websitePreview ? (
               <Fragment>
                  <InputAdornment position="start" className={'nx_textfield_website_preview'}>
                     <IconButton
                        onClick={() =>
                           window.open(
                              this.props.value.includes('http') ? this.props.value : `https://${this.props.value}`
                           )
                        }
                     >
                        <PreviewIcon />
                     </IconButton>
                  </InputAdornment>
               </Fragment>
            ) : null}
         </Fragment>
      );
   }
}

Nx_Ctrl_TextInput.propTypes = {
   className: PropTypes.string,
   type: PropTypes.oneOf(['text', 'password', 'number', 'color', 'file']),
   disabled: PropTypes.bool,
   label: PropTypes.string,
   variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
   fullWidth: PropTypes.bool,
   autoFocus: PropTypes.bool,
   error: PropTypes.bool,
   helperText: PropTypes.string,
   fontSize: PropTypes.string,
   fontLabelSize: PropTypes.string,
   multiline: PropTypes.bool,
   size: PropTypes.oneOf(['medium', 'small', 'string'])
};

Nx_Ctrl_TextInput.defaultProps = {
   className: '',
   disabled: false,
   fullWidth: true,
   autoFocus: false,
   error: false,
   helperText: '',
   type: 'text',
   fontSize: '12px',
   fontLabelSize: '11px',
   multiline: false,
   size: 'small'
};

export default Nx_Ctrl_TextInput;
