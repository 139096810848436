import React, { useEffect, useState } from 'react';
import './home.scss';
import Card from './components/Card';
import LineChart from './components/LineChart';
import Nx_Loader from '../../../nix_components/components/nx_loader';

const months = {
   '01': 'Јануари',
   '02': 'Фебруари',
   '03': 'Март',
   '04': 'Април',
   '05': 'Мај',
   '06': 'Јуни',
   '07': 'Јули',
   '08': 'Август',
   '09': 'Септември',
   10: 'Октомври',
   11: 'Ноември',
   12: 'Декември'
};

export default function Home({ user, router, region_id }) {
   const [patients, setPatients] = useState(0);
   const [subjects_first_check_up, setFirstCheckUp] = useState(0);
   const [subjects_appeal, setAppeal] = useState(0);
   const [subjects_control_check_up, setControlCheckUp] = useState(0);
   const [patients_chart, setPatientsChart] = useState([]);
   const [subjects_chart, setSubjectsChart] = useState([]);
   const [initializing, setInitializing] = useState(true);

   const dates = [];
   let startDate = new Date();
   startDate.setMonth(startDate.getMonth() - 5);
   startDate.setDate(1);

   for (var i = 0; i < 6; i++) {
      dates.push(startDate.toISOString().substring(0, 10));
      startDate.setMonth(startDate.getMonth() + 1);
   }

   const cards = [
      {
         title: 'Вкупно активни пациенти',
         count: patients,
         link: '/patients'
      },
      {
         title: 'Прва проценка во тек',
         count: subjects_first_check_up,
         link: '/'
      },
      {
         title: 'Контролни проценки во тек',
         count: subjects_control_check_up,
         link: '/'
      },
      {
         title: 'Барања за второ мислење',
         count: subjects_appeal,
         link: '/'
      }
   ];

   const renderMonths = () => {
      const temp_month_arr = [];
      dates.forEach((date) => {
         const formated_date = date.substring(5, 7);
         temp_month_arr.push(months[formated_date]);
      });
      return temp_month_arr;
   };

   const dataLinePatients = {
      labels: renderMonths(),
      datasets: [
         {
            label: 'Графикон за ново креирани пациенти во изминатите 6 месеци',
            data: patients_chart,
            fill: true,
            min: 0,
            backgroundColor: '#0047ab',
            borderColor: '#0047ab'
         }
      ],
      scales: {
         x: {
            min: 0
         }
      }
   };

   const dataLineDocuments = {
      labels: renderMonths(),
      datasets: [
         {
            label: 'Графикон за новокреирани документи за изминатите 6 месеци',
            data: subjects_chart,
            fill: true,
            backgroundColor: '#0047ab',
            borderColor: '#0047ab'
         }
      ],
      scales: {
         x: {
            min: 0
         }
      }
   };

   const renderCards = () => {
      return cards.map((card, key) => {
         return <Card {...card} key={key} />;
      });
   };

   const handleInitialization = async () => {
      const hasRegion = !Array.isArray(region_id);
      console.log(hasRegion);

      console.log(region_id);

      const view_stats_regions_patients_and_subjects = await global.UF.dataProvider.get_v2(
         `view_stats_regions_patients_and_subjects`,
         { id: region_id }
      );

      const view_stats_all_patients_by_created_at = await global.UF.dataProvider.get_v2(
         `view_stats_regions_patients_by_created_at`,
         { region_id: region_id }
      );

      const view_stats_all_subjects_created_at = await global.UF.dataProvider.get_v2(
         `view_stats_regions_subjects_created_at`,
         { region_id: region_id }
      );

      const generated_all_patients = [];
      const generated_all_subjects = [];

      dates.forEach((date) => {
         const found_patients = view_stats_all_patients_by_created_at.find((p) => p.created_at_date == date);

         const found_subject = view_stats_all_subjects_created_at.find((s) => s.created_at_date == date);

         if (!found_subject) {
            generated_all_subjects.push(0);
         } else {
            generated_all_subjects.push(found_subject.subjects_count);
         }

         if (!found_patients) {
            generated_all_patients.push(0);
         } else {
            generated_all_patients.push(found_patients.patients_count);
         }
      });

      let patients = 0;
      let subjects_first_check_up = 0;
      let subjects_appeal = 0;
      let subjects_control_check_up = 0;

      if (view_stats_regions_patients_and_subjects.length > 1) {
         view_stats_regions_patients_and_subjects.forEach((element) => {
            patients = patients + element.patients;
            subjects_first_check_up = subjects_first_check_up + element.subjects_first_check_up;
            subjects_appeal = subjects_appeal + element.subjects_appeal;
            subjects_control_check_up = subjects_control_check_up + element.subjects_control_check_up;
         });
      } else {
         const cards_data = view_stats_regions_patients_and_subjects[0];

         patients = cards_data ? cards_data.patients : 0;
         subjects_first_check_up = cards_data ? cards_data.subjects_first_check_up : 0;
         subjects_appeal = cards_data ? cards_data.subjects_appeal : 0;
         subjects_control_check_up = cards_data ? cards_data.subjects_control_check_up : 0;
      }

      setPatients(patients);
      setFirstCheckUp(subjects_first_check_up);
      setAppeal(subjects_appeal);
      setControlCheckUp(subjects_control_check_up);
      setPatientsChart(generated_all_patients);
      setSubjectsChart(generated_all_subjects);

      // if (hasRegion) {
      //    const view_stats_regions_patients_and_subjects = await global.UF.dataProvider.get_v2(
      //       `view_stats_regions_patients_and_subjects`,
      //       { id: region_id }
      //    );

      //    const view_stats_all_patients_by_created_at = await global.UF.dataProvider.get_v2(
      //       `view_stats_regions_patients_by_created_at`,
      //       { region_id: region_id }
      //    );

      //    const view_stats_all_subjects_created_at = await global.UF.dataProvider.get_v2(
      //       `view_stats_regions_subjects_created_at`,
      //       { region_id: region_id }
      //    );

      //    const generated_all_patients = [];
      //    const generated_all_subjects = [];

      //    dates.forEach((date) => {
      //       const found_patients = view_stats_all_patients_by_created_at.find((p) => p.created_at_date == date);

      //       const found_subject = view_stats_all_subjects_created_at.find((s) => s.created_at_date == date);

      //       if (!found_subject) {
      //          generated_all_subjects.push(0);
      //       } else {
      //          generated_all_subjects.push(found_subject.subjects_count);
      //       }

      //       if (!found_patients) {
      //          generated_all_patients.push(0);
      //       } else {
      //          generated_all_patients.push(found_patients.patients_count);
      //       }
      //    });

      //    const cards_data = view_stats_regions_patients_and_subjects[0];

      //    const patients = cards_data ? cards_data.patients : 0;
      //    const subjects_first_check_up = cards_data ? cards_data.subjects_first_check_up : 0;
      //    const subjects_appeal = cards_data ? cards_data.subjects_appeal : 0;
      //    const subjects_control_check_up = cards_data ? cards_data.subjects_control_check_up : 0;

      //    setPatients(patients);
      //    setFirstCheckUp(subjects_first_check_up);
      //    setAppeal(subjects_appeal);
      //    setControlCheckUp(subjects_control_check_up);
      //    setPatientsChart(generated_all_patients);
      //    setSubjectsChart(generated_all_subjects);
      // } else {
      //    const view_stats_all_patients_and_subject = await global.UF.dataProvider.get_v2(
      //       `view_stats_all_patients_and_subject`,
      //       {}
      //    );
      //    const view_stats_all_patients_by_created_at = await global.UF.dataProvider.get_v2(
      //       `view_stats_all_patients_by_created_at`,
      //       {}
      //    );

      //    const view_stats_all_subjects_created_at = await global.UF.dataProvider.get_v2(
      //       `view_stats_all_subjects_created_at`,
      //       {}
      //    );

      //    const generated_all_patients = [];
      //    const generated_all_subjects = [];

      //    dates.forEach((date) => {
      //       const found_patients = view_stats_all_patients_by_created_at.find((p) => p.created_at_date == date);

      //       const found_subject = view_stats_all_subjects_created_at.find((s) => s.created_at_date == date);

      //       if (!found_subject) {
      //          generated_all_subjects.push(0);
      //       } else {
      //          generated_all_subjects.push(found_subject.subjects_count);
      //       }

      //       if (!found_patients) {
      //          generated_all_patients.push(0);
      //       } else {
      //          generated_all_patients.push(found_patients.patients_count);
      //       }
      //    });

      //    const cards_data = view_stats_all_patients_and_subject[0];

      //    const patients = cards_data.patients;
      //    const subjects_first_check_up = cards_data.subjects_first_check_up;
      //    const subjects_appeal = cards_data.subjects_appeal;
      //    const subjects_control_check_up = cards_data.subjects_control_check_up;

      //    setPatients(patients);
      //    setFirstCheckUp(subjects_first_check_up);
      //    setAppeal(subjects_appeal);
      //    setControlCheckUp(subjects_control_check_up);
      //    setPatientsChart(generated_all_patients);
      //    setSubjectsChart(generated_all_subjects);
      // }
   };

   useEffect(() => {
      const fetchData = async () => {
         await handleInitialization();
         setInitializing(false);
      };

      fetchData();
   }, [region_id]);

   return (
      <div className="home_wrapper">
         <div className="home_content_wrapper">
            <div className="home_cards_wrapper">{renderCards()}</div>
            <div className="home_charts_wrapper">
               <LineChart data={dataLinePatients} />
               <LineChart data={dataLineDocuments} />
            </div>
         </div>
      </div>
   );
}
