import React, { Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Menu from '../../nix_components/ui_components/components/Menu/Menu.js';
import Grid from '../../nix_components/ui_components/layouts/Grid/Grid';
import withRouter from '../../nix_components/components/withRouter.js';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from '../SharedScreens/Home/Home.jsx';
import Patients from '../SharedScreens/Patients/Patients.jsx';
import AccessibleIcon from '@mui/icons-material/Accessible';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MyProfile from '../SharedScreens/MyProfile/MyProfile.jsx';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Subjects from '../SharedScreens/Subjects/Subjects.jsx';
import AppealBook from '../SharedScreens/AppealBook/AppealBook.jsx';
import ScheduleUps from '../SharedScreens/ScheduleUps/ScheduleUps.jsx';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Appeal from '../SharedScreens/Subjects/Appeal.jsx';
import ArticleIcon from '@mui/icons-material/Article';
import MySubjects from './MySubjects.jsx';
import FirstCheckup from '../SharedScreens/PatientsBook/FirstCheckup.jsx';
import ControlCheckup from '../SharedScreens/PatientsBook/ControlCheckup.jsx';

class Doctor extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         showMenu: true,
         availableRegions: [],
         region_id: global.UF.region_id ? global.UF.region_id : ''
      };

      this.menuItems = [
         {
            title: 'Почетна страна',
            level: 1,
            scope: 'home',
            url: '/home',
            icon: <HomeIcon />,
            element: () => {
               return <Home {...props} region_id={this.state.region_id} />;
            }
         },
         {
            title: 'Мои предмети',
            level: 1,
            url: '/my_subjects',
            icon: <ArticleIcon />,
            element: () => {
               return <MySubjects {...props} region_id={this.state.region_id} />;
            }
         },
         {
            title: 'Пациенти',
            level: 1,
            scope: 'patients',
            url: '/patients/:id?',
            icon: <AccessibleIcon />,
            element: () => {
               return <Patients {...props} add={true} edit={true} region_id={this.state.region_id} />;
            }
         },
         {
            title: 'Предмет',
            hide: true,
            url: '/subjects/:patient_id/:id/:type/:is_printed?',
            element: () => {
               return <Subjects {...props} />;
            }
         },
         {
            title: 'Жалба',
            hide: true,
            url: '/appeal/:patient_id/:id/:type/:is_printed?',
            element: () => {
               return <Appeal {...props} />;
            }
         },
         {
            title: 'Деловодна книга',
            level: 1,
            scope: 'patients_book_appeal',
            icon: <MenuBookIcon />,
            children: [
               {
                  title: 'Прва проценка',
                  level: 2,
                  scope: 'patients_book',
                  url: './patients_book/first_checkup',
                  element: () => {
                     return <FirstCheckup edit={false} {...props} />;
                  }
               },
               {
                  title: 'Контролна проценка',
                  level: 2,
                  scope: 'patients_book',
                  url: './patients_book/control_checkup',
                  element: () => {
                     return <ControlCheckup edit={false} {...props} />;
                  }
               },
               {
                  title: 'Жалби',
                  level: 2,
                  scope: 'patients_book_appeal',
                  url: './patients_book/appeal',
                  element: () => {
                     return <AppealBook edit={false} {...props} />;
                  }
               }
            ]
         },
         {
            title: 'Деловодна книга на префрлени предмети',
            level: 1,
            scope: 'patients_book_appeal',
            icon: <MenuBookIcon />,
            children: [
               {
                  title: 'Прва проценка',
                  level: 2,
                  scope: 'patients_book',
                  url: './patients_book_transfered/first_checkup',
                  element: () => {
                     return <FirstCheckup key={'transfered'} {...props} transfered={true} />;
                  }
               },
               {
                  title: 'Контролна проценка',
                  level: 2,
                  scope: 'patients_book',
                  url: './patients_book_transfered/control_checkup',
                  element: () => {
                     return <ControlCheckup key={'transfered'} {...props} transfered={true} />;
                  }
               },
               {
                  title: 'Жалби',
                  level: 2,
                  scope: 'patients_book_appeal',
                  url: './patients_book_transfered/appeal',
                  element: () => {
                     return <AppealBook key={'transfered'} {...props} transfered={true} />;
                  }
               }
            ]
         },
         {
            title: 'Закажани проценки',
            level: 1,
            scope: 'schedule_ups',
            url: '/schedule_ups',
            icon: <FormatListBulletedIcon />,
            element: () => {
               return <ScheduleUps {...props} region_id={this.state.region_id} />;
            }
         },
         {
            title: 'Мој профил',
            level: 1,
            url: '/profile',
            icon: <AccountCircleIcon />,
            element: () => {
               return <MyProfile {...props} />;
            }
         },
         {
            title: 'Одјави се',
            level: 1,
            onClick: this.handleLogout,
            icon: <LogoutIcon />
         }
      ];
   }

   handleRegionChange = (value) => {
      global.UF.region_id = value;
      this.setState({ region_id: value });
   };

   handleLogout = () => {
      localStorage.clear('token');
      localStorage.clear('user');
      window.location.href = '/';
   };

   handleRegions = async () => {
      const { user } = this.props;
      const regions = JSON.parse(user.region_id);
      let availableRegions = await global.UF.dataProvider.get_v2(`regions`, {
         id: regions
      });

      const hasKT = availableRegions.findIndex((r) => r.code == 'КТ') > -1;

      if (hasKT) {
         this.props.user.has_kt = true;
      }

      availableRegions = availableRegions.map((region) => ({
         id: region.id,
         label: region.name
      }));

      const regionIds = availableRegions.map((region) => region.id);

      const KT = availableRegions.find((ar) => ar.label == 'Координативно тело');

      if (KT) {
         KT.id = [...regionIds, 'KT'];
      }

      if (availableRegions.length) {
         availableRegions.unshift({
            id: regionIds,
            label: 'Сите региони'
         });
      }
      global.UF.region_id = regionIds;
      this.setState({ availableRegions, region_id: regionIds });
   };

   renderElement = () => {
      const menuItems = this.menuItems.filter((menuItem) => !menuItem.hide);
      this.routes = this.renderRoutes();
      this.routes.push(<Route path="/*" key={new Date().getTime()} element={<Navigate to="/home" />} />);
      return (
         <Grid fullScreen={true} className="uf_layout">
            <Grid className="menu_layout" hidden={!this.state.showMenu}>
               <Menu
                  items={menuItems}
                  regions={this.state.availableRegions}
                  region_id={this.state.region_id}
                  handleRegionChange={this.handleRegionChange}
               />
            </Grid>
            <Grid className="content_layout">
               <Routes>{this.routes}</Routes>
            </Grid>
         </Grid>
      );
   };

   renderRoutes = () => {
      if (this.menuItems && this.menuItems.length) {
         return this.menuItems.map((menuItem, index) => {
            if (menuItem.hasOwnProperty('children')) {
               return menuItem.children.map((child, key) => {
                  let currentMenuItem = menuItem.children.find((item) => item.url === child.url);

                  return (
                     <Route
                        path={`/${currentMenuItem.url.split('/')[1]}/${currentMenuItem.url.split('/')[2]}`}
                        key={key}
                        element={currentMenuItem.element()}
                     />
                  );
               });
            } else {
               if (menuItem.hasOwnProperty('url') && menuItem.url) {
                  let currentMenuItem = this.menuItems.find((item) => item.url === menuItem.url);
                  const split = currentMenuItem.url.split('/');
                  let tempUrl = '';
                  if (split.length > 2) {
                     split.slice(0, 1);
                     tempUrl = split.join('/');
                  } else {
                     tempUrl = split[1];
                  }
                  return <Route path={`/${tempUrl}`} key={index} element={currentMenuItem.element()} />;
               }
            }
         });
      } else return null;
   };

   async componentDidMount() {
      await this.handleRegions();
   }

   render() {
      return <Fragment>{this.renderElement()}</Fragment>;
   }
}

export default withRouter(Doctor);
